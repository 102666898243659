import { type MouseEvent, type MutableRefObject, type FC, type ReactNode } from 'react';
import classnames from 'classnames';
import { useIntl } from 'dibs-react-intl';
import { handleLocaleUrl } from 'dibs-intl/exports/urls';

// styles
import styles from './main.scss';
type ButtonType = 'button';
type ResetType = 'reset';
type SubmitType = 'submit';

export type ButtonTypesType = ButtonType | ResetType | SubmitType | undefined;

type StyledButtonProps = {
    className?: string;
    children?: ReactNode;
    dataTn?: string;
    disabled?: boolean;
    href?: string;
    isPrimary?: boolean;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    rel?: string;
    tabIndex?: number;
    target?: string;
    type?: ButtonTypesType;
    value?: string;
    ariaLabel?: string;
    buttonRef?:
        | ((element: HTMLElement | null) => void)
        | MutableRefObject<HTMLButtonElement | HTMLAnchorElement | null>;
};

export const StyledButton: FC<StyledButtonProps> = props => {
    const {
        className,
        children,
        dataTn,
        disabled,
        href,
        isPrimary = false,
        onClick,
        rel,
        tabIndex,
        target,
        type,
        value,
        ariaLabel,
        buttonRef,
    } = props;

    const styledButtonClasses = classnames(
        styles.button,
        {
            [styles.primaryButton]: isPrimary,
            [styles.disabled]: disabled,
        },
        className
    );

    const extraProps = {
        disabled,
        onClick,
        rel,
        tabIndex,
        target,
        value,
    };
    const intl = useIntl();
    if (typeof href === 'string') {
        return (
            // this is the base element so this is allowed here
            // eslint-disable-next-line react/forbid-elements
            <a
                ref={
                    buttonRef as
                        | ((element: HTMLElement | null) => void)
                        | MutableRefObject<HTMLAnchorElement>
                }
                aria-label={ariaLabel}
                className={styledButtonClasses}
                data-tn={dataTn}
                href={handleLocaleUrl(disabled ? undefined : href, intl.locale)}
                {...extraProps}
            >
                {children}
            </a>
        );
    } else {
        return (
            // this is the base element so this is allowed here
            // eslint-disable-next-line react/forbid-elements
            <button
                ref={
                    buttonRef as
                        | ((element: HTMLElement | null) => void)
                        | MutableRefObject<HTMLButtonElement>
                }
                aria-label={ariaLabel}
                className={styledButtonClasses}
                data-tn={dataTn}
                // might want to revisit this logic to add a condition
                // to ensure `type` is not undefined before rendering a button
                // eslint-disable-next-line react/button-has-type
                type={type}
                {...extraProps}
            >
                {children}
            </button>
        );
    }
};
