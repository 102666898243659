import { type MouseEvent, type FC, type MutableRefObject, type ReactNode } from 'react';
import classNames from 'classnames';

//components
import { StyledButton, type ButtonTypesType } from '../Common/Button/StyledButton';

// styles
import styles from './main.scss';

export type ButtonProps = {
    className?: string;
    children?: ReactNode;
    dataTn?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    href?: string;
    isDangerousAction?: boolean;
    noFollow?: boolean;
    onClick?: (e: MouseEvent<HTMLElement>) => void;
    opensInNewWindow?: boolean;
    size?: 'small' | 'medium' | 'large';
    target?: string;
    type?: 'primary' | 'primaryAlt' | 'secondary' | 'secondaryAlt' | 'textCta' | 'transparent';
    htmlType?: ButtonTypesType;
    ariaLabel?: string;
    role?: 'menuitem';
    buttonRef?:
        | ((element: HTMLElement | null) => void)
        | MutableRefObject<HTMLButtonElement | HTMLAnchorElement | null>;
    /**
     * `wrap` does not affect Buttons with HTML element descendants. `wrap` allows line-wrapping on
     * Buttons by applying inline-flex, line-height and white-space rules. disable to use legacy
     * inline-block line-height = Button height layout. please note that Buttons do not have include
     * any overflow rules.
     * @default true
     */
    wrap?: boolean;
};

export const Button: FC<ButtonProps> = ({
    children,
    dataTn,
    className,
    disabled,
    fullWidth,
    href,
    isDangerousAction,
    noFollow,
    onClick = () => {},
    opensInNewWindow,
    size = 'large',
    target,
    type = 'primary',
    htmlType,
    ariaLabel,
    buttonRef,
    wrap = true,
}) => {
    if (
        !['primary', 'primaryAlt', 'secondary', 'secondaryAlt', 'textCta', 'transparent'].includes(
            type
        ) &&
        process.env.NODE_ENV !== 'production'
    ) {
        // eslint-disable-next-line no-console
        console.warn(
            `dibs-elements | Button | Type Error: You have passed in "${type}" for prop "type". Only 'primary', 'primaryAlt', 'secondary', 'secondaryAlt', 'textCta', or 'transparent' is allowed. Did you mean to use "htmlType"?`
        );
    }
    const isPrimary = ![
        'primaryAlt',
        'secondary',
        'secondaryAlt',
        'textCta',
        'transparent',
    ].includes(type);

    const outputClassName = classNames(
        {
            [styles.primaryAltButton]: type === 'primaryAlt',
            [styles.secondaryButton]: type === 'secondary',
            [styles.secondaryAltButton]: type === 'secondaryAlt',
            [styles.transparentButton]: type === 'transparent',
            [styles.textCtaButton]: type === 'textCta',
            [styles.isDangerousActionPrimary]: isDangerousAction && type === 'primary',
            [styles.isDangerousActionSecondary]:
                isDangerousAction && type !== 'primary' && type !== 'transparent',
            [styles.isDangerousActionTransparent]: isDangerousAction && type === 'transparent',
            [styles.fullWidth]: fullWidth,
            [styles.disabled]: disabled,
            [styles.noWrap]: !wrap,
            [styles.wrap]: wrap,
        },
        styles[size],
        className
    );
    const optionalProps: {
        disabled?: boolean;
        target?: string;
        rel?: string;
        type?: ButtonTypesType;
        ariaLabel?: string;
    } = { ariaLabel };
    if (disabled) {
        optionalProps.disabled = true;
    }
    if (typeof href === 'string') {
        if (opensInNewWindow) {
            optionalProps.target = '_blank';
            optionalProps.rel = 'noopener'; // https://developers.google.com/web/tools/lighthouse/audits/noopener
        }
        if (noFollow) {
            if (optionalProps.rel) {
                optionalProps.rel += ' nofollow';
            } else {
                optionalProps.rel = 'nofollow';
            }
        }
        if (target) {
            optionalProps.target = target;
        }
        return (
            <StyledButton
                className={outputClassName}
                dataTn={dataTn}
                href={href}
                isPrimary={isPrimary}
                onClick={onClick}
                buttonRef={buttonRef}
                {...optionalProps}
            >
                {children}
            </StyledButton>
        );
    }
    if (htmlType) {
        optionalProps.type = htmlType;
    }

    return (
        <StyledButton
            className={outputClassName}
            dataTn={dataTn}
            isPrimary={isPrimary}
            onClick={onClick}
            buttonRef={buttonRef}
            {...optionalProps}
        >
            {children}
        </StyledButton>
    );
};
